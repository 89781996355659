import styled from '@emotion/styled';
import Container from '../Container';
import { breakpoint } from '../../style/utils';

export const NotFoundContainer = styled(Container)`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const NotFoundPageRoot = styled.main`
  padding-bottom: 60px;
  height: 420px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoint({ max: 'xs' })} {
    height: 300px;
  }
`;

export const NotFoundPageTitle = styled.h1`
  margin-top: 0;
  font-size: 48px;
`;

export const NotFoundPageDescription = styled.p`
  font-size: 24px;
`;
