import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

import Navbar from '../Navbar';
import SEOTags from '../SEOTags';
import ArticlePreviewList from '../ArticlePreviewList';
import Layout from '../Layout';
import openGraphImage from '../../assets/open-graph-image.jpg';
import {
  NotFoundContainer,
  NotFoundPageRoot,
  NotFoundPageTitle,
  NotFoundPageDescription,
} from './NotFoundPage.style';
import { NotFoundPageQuery } from '../../../generated/graphql-types';
import Divider from '../Divider';

interface NotFoundPageProps {
  data: NotFoundPageQuery;
}

const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({ data }) => {
  const siteMetadata = data?.site?.siteMetadata;
  const articles = data.allArticles.nodes;

  return (
    <Layout>
      <Navbar />
      <SEOTags
        title={siteMetadata?.title || 'The Planty Blog'}
        description={siteMetadata?.description || ''}
        canonical={siteMetadata?.siteUrl || ''}
        openGraph={{
          title: siteMetadata?.title || 'The Planty Blog',
          description: siteMetadata?.description || '',
          type: 'website',
          url: siteMetadata?.siteUrl || '',
          images: [
            {
              url: `${siteMetadata?.siteUrl}${openGraphImage}`,
              alt: siteMetadata?.title || '',
            },
          ],
        }}
      />
      <NotFoundContainer>
        <NotFoundPageRoot>
          <NotFoundPageTitle>Nothing to see here...</NotFoundPageTitle>
          <NotFoundPageDescription>
            We couldn't find anything here. Trying heading{' '}
            <Link to="/">home</Link>?
          </NotFoundPageDescription>
        </NotFoundPageRoot>
        <Divider />
        <h2>Some articles you might be interested in</h2>
        <ArticlePreviewList articles={articles} />
      </NotFoundContainer>
    </Layout>
  );
};

export default NotFoundPage;
